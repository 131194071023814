import { Anchor, Group, Text } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  STORE_LISTINGS_API_URL,
  StoreListing,
  useArchiveStoreListing,
  useDeleteStoreListing,
  useSortableStoreListings,
  useStoreListings,
} from '@portals/api/partners';
import { CopyToClipboard } from '@portals/core';
import {
  StoreListingStatusBadge,
  useConfirmationModal,
} from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { useOpenModal } from '@portals/redux';
import { AvatarCell, PaginatedTable } from '@portals/table';
import {
  PaginatedTableProps,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { getPricingModelDisplayName } from '@portals/utils';

import { CurrencySelector } from './CurrencySelector';
import { StoreListingsTableDetailsPanel } from './details-panel/StoreListingsTableDetailsPanel';
import { StoreListingsTableRowMenuItems } from './StoreListingsTableRowMenuItems';
import { StorePricesCell } from './StorePricesCell';
import { StoreListingWizardModalProps } from '../../../modals';
import { AddNewProductButton } from '../AddNewProductButton';
import { RearrangeStoreButton } from '../RearrangeStoreButton';

const COLUMNS: TableColumn<StoreListing>[] = [
  {
    dataField: 'product_name',
    text: 'Product name',
    isSticky: true,
    minWidth: 300,
    formatter: (_, row) => (
      <AvatarCell
        src={row.product_image_url}
        label={row.product_name}
        withAbbreviation={false}
        radius="md"
        color="blue_gray"
      >
        <Gallery />
      </AvatarCell>
    ),
  },
  {
    dataField: 'id',
    text: 'ID',
    minWidth: 300,
    hidden: true,
    filter: {
      type: TableFilterTypeEnum.Text,
    },
    formatter: (_, row) => (
      <Group noWrap>
        <Text truncate>{row.id}</Text>
        <CopyToClipboard value={row.id} />
      </Group>
    ),
  },
  {
    minWidth: 300,
    dataField: 'product.device_model.name',
    text: 'Device model',
    formatter: (_, row) => {
      if (!row.product.device_model) return null;

      return (
        <Anchor to={`/models/${row.product.device_model.id}`} component={Link}>
          {row.product.device_model.name}
        </Anchor>
      );
    },
  },
  {
    minWidth: 300,
    dataField: 'manufacturer',
    text: 'Manufacturer',
    formatter: (_, row) => (
      <AvatarCell
        src={row.product.partner_logo_url}
        label={row.product.partner_name}
      />
    ),
  },
  {
    minWidth: 300,
    dataField: 'product.category',
    text: 'Category',
    formatter: (_, row) => (
      <Text transform="uppercase" truncate>
        {row.product.category}
      </Text>
    ),
  },
  {
    minWidth: 300,
    dataField: 'product.pricing_model',
    text: 'Pricing model',
    formatter: (_, row) =>
      getPricingModelDisplayName(row.product.pricing_model),
  },
  {
    dataField: 'prices',
    text: 'Store Price',
    headerFormatter: () => <CurrencySelector />,
    minWidth: 350,
    formatter: (_, row) => <StorePricesCell storeListing={row} />,
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: (_, row) => <StoreListingStatusBadge status={row.status} />,
  },
  {
    dataField: 'product.sku',
    text: 'Product SKU',
    hidden: true,
  },
];

interface StoreListingsTableProps
  extends Omit<
    PaginatedTableProps<StoreListing, 'id'>,
    'name' | 'keyField' | 'columns'
  > {
  storeListingStatus: StoreListing['status'] | undefined;
}

export function StoreListingsTable({
  storeListingStatus,
  ...tableProps
}: StoreListingsTableProps) {
  const openModal = useOpenModal();
  const asyncConfirmation = useConfirmationModal();

  const deleteStoreListing = useDeleteStoreListing();
  const archiveStoreListing = useArchiveStoreListing();

  const sortableStoreListings = useSortableStoreListings();

  const showRearrangeStoreButton =
    (storeListingStatus === undefined || storeListingStatus === 'published') &&
    sortableStoreListings.data?.length;

  const onDelete = async (storeListing: StoreListing) => {
    const isConfirmed = await asyncConfirmation({
      title: 'Delete product listing',
      description: 'Are you sure you want to delete this product listing?',
      confirmationLabel: 'Delete',
    });

    if (isConfirmed) {
      deleteStoreListing.mutate({
        storeListingId: storeListing.id,
      });
    }
  };

  const onArchive = async (storeListing: StoreListing) => {
    const isConfirmed = await asyncConfirmation({
      title: 'Archive product listing',
      description: 'Are you sure you want to archive this product listing?',
      confirmationLabel: 'Archive',
    });

    if (isConfirmed) {
      archiveStoreListing.mutate({
        storeListingId: storeListing.id,
      });
    }
  };

  const openStoreListingWizardModal = (
    storeListing: StoreListing,
    intent: StoreListingWizardModalProps['data']['intent']
  ) => {
    openModal<StoreListingWizardModalProps['data']>('StoreListingWizardModal', {
      storeListing: storeListing,
      intent,
    });
  };

  return (
    <PaginatedTable<StoreListing>
      keyField="id"
      name="partner-store-listings-all"
      columns={COLUMNS}
      dataHook={useStoreListings}
      dataHookUrl={
        storeListingStatus
          ? `${STORE_LISTINGS_API_URL}/?q[status_eq]=${storeListingStatus}`
          : undefined
      }
      // onSelected={noop}
      // selectedItemsActions={() => (
      //   <StoreListingsTableActions
      //     onArchive={onArchive}
      //     onDelete={onDelete}
      //     onEdit={(storeListing) =>
      //       openStoreListingWizardModal(storeListing, 'edit')
      //     }
      //     onPublish={(storeListing) =>
      //       openStoreListingWizardModal(storeListing, 'publish')
      //     }
      //   />
      // )}
      additionalActions={() => (
        <Group>
          {showRearrangeStoreButton && <RearrangeStoreButton />}
          <AddNewProductButton />
        </Group>
      )}
      rowMenu={({ row, wrapperProps }) => (
        <StoreListingsTableRowMenuItems
          storeListing={row.original}
          wrapperProps={wrapperProps}
          onArchive={onArchive}
          onDelete={onDelete}
          onEdit={(storeListing) =>
            openStoreListingWizardModal(storeListing, 'edit')
          }
          onPublish={(storeListing) =>
            openStoreListingWizardModal(storeListing, 'publish')
          }
        />
      )}
      detailsPanel={{
        type: 'page',
        width: 'clamp(700px, 50%, 1000px)',
        renderer: ({ row, onClose }) => (
          <StoreListingsTableDetailsPanel
            storeListing={row.original}
            onClose={onClose}
            onArchive={onArchive}
            onDelete={onDelete}
            onEdit={(storeListing) =>
              openStoreListingWizardModal(storeListing, 'edit')
            }
            onPublish={(storeListing) =>
              openStoreListingWizardModal(storeListing, 'publish')
            }
          />
        ),
      }}
      {...tableProps}
    />
  );
}
